'use strict';

export function externalLink(
  translation = '',
  selector = 'a[href*="/file/"], a[href*="/data/asset/"], a[href$=".pdf"]',
  notSelector = '[href*="' + window.location.hostname + '"],.not-external,.btn',
) {
  const $ = require('jquery');
  $(selector).not(notSelector).attr('target', '_blank').addClass('ems-external-link').append(translation);
}
