'use strict';
export const cookiesBar = {
  init: function (selector) {
    selector = selector ?? '.js-cookies-bar';
    window.addEventListener('load', this.setup(selector));
  },

  setup: function (selector) {
    const bar = document.querySelector(selector);

    if (bar) {
      const cookie = this.getCookie('cookieBanner');
      if (cookie) {
        bar.style.display = 'none';
        return null;
      }

      const closeButton = document.querySelector('#closeCookie');
      closeButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.setCookie('cookieBanner', 'true', 30);
        bar.style.display = 'none';
      });
    }
  },

  setCookie: function (cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  },

  getCookie: function (cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  },
};
